@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply h-screen
        font-display
        antialiased
        accent-violet-300
        selection:bg-violet-300
        text-violet-50
        selection:text-violet-900
        2xs:text-[16px]
        xs:text-[18px]
        md:text-[20px]
        lg:text-[22px]
        xl:text-[24px];
        background-image: linear-gradient(90deg, #761e82 0%, #005a8e 70%);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
    }

    input,
    select {
        @apply input;
    }

    /* input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
    } */

    /* input {
        -moz-appearance: textfield !important;
    } */

    input[type="radio"] {
        @apply border-0 focus:ring-0;
    }

    input[type="range"] {
        @apply h-fit bg-transparent;
    }

    input[type="number"]::-webkit-inner-spin-button {
        @apply h-full appearance-none;
    }

    input[type="search"] {
        -webkit-appearance: none !important;
    }

    input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none !important;
        @apply ml-1 mr-0 h-6 w-6 cursor-pointer;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='%23c4b5fd80' stroke-width='2'><path stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /></svg>");
    }

    .indigo input[type="search"]::-webkit-search-cancel-button {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='%23312e8180' stroke-width='2'><path stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /></svg>");
    }

    table {
        border-spacing: 3px;
        border-collapse: separate;
    }

    h2 {
        @apply text-[20px] font-medium;
    }

    p {
        @apply mb-3;
    }
}

@layer components {
    .input {
        @apply h-10
        w-full
        px-2
        rounded-lg
        border-2
        border-violet-300/50
        bg-indigo-900/20
        font-medium
        text-[1.25em]
        text-violet-50
        hover:outline-none
        focus:outline-none
        placeholder:text-left
        placeholder:text-violet-50/40
        placeholder-shown:font-display
        placeholder-shown:text-[0.875em]
        focus:ring-violet-300/50
        focus:ring-2;
    }

    .root {
        @apply h-screen overflow-x-hidden;
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }

    .nav-link {
        @apply rounded p-1 font-medium text-violet-200 hover:bg-gray-100/10 hover:text-violet-50;
    }

    .group-title {
        @apply input-label text-center text-[0.875em] uppercase tracking-wider font-medium;
    }

    .group-title.light-box {
        @apply shadow-xl;
    }

    .card {
        @apply rounded-2xl p-3 shadow backdrop-blur;
    }

    .card-header-title-text {
        @apply whitespace-nowrap text-[0.9375em] font-medium uppercase md:tracking-wider;
    }

    .card-header-item-text {
        @apply text-[0.75em];
    }

    .input-label {
        @apply overflow-ellipsis break-normal text-[0.875em] font-medium;
    }

    .tooltip {
        @apply text-[0.875em];
    }

    .glass:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 70%
        );
        pointer-events: none; /*If not put, controls inside become non clickable.*/
        @apply rounded-t;
    }

    .dark-box {
        @apply rounded-lg bg-gray-900/10 p-2;
    }

    .light-box {
        @apply rounded-lg bg-gray-50/5 p-2;
    }

    .lighter-box {
        @apply rounded-lg bg-gray-50/[0.015] p-2;
    }

    .glass-box {
        @apply glass relative rounded-lg bg-gray-50/[0.015] p-2;
    }

    /*BUTTONS*/
    .btn {
        @apply /*relative is needed for the glass effect.*/ relative rounded-lg border-0 font-medium
        uppercase shadow-md ring-1 focus:outline-none focus:ring-4 disabled:opacity-75 sm:tracking-wide;
    }

    .btn.active {
        @apply ring-4;
    }

    .btn-close {
        @apply hover:bg-red-500
        active:bg-red-700
        disabled:bg-red-800
        focus:ring-red-700/20
        ring-0
        hover:text-violet-50;
    }

    .btn-dark {
        @apply bg-[#212529]
        hover:bg-[#1c1f23]
        active:bg-[#212529]
        disabled:bg-[#212529]
        ring-white/20
        focus:ring-[#212529]/50;
    }

    .btn-slate {
        @apply bg-slate-700
        hover:bg-slate-600
        active:bg-slate-700
        disabled:bg-slate-800
        ring-slate-500/80
        focus:ring-slate-700/50;
    }

    .btn-green {
        @apply bg-green-700
        hover:bg-green-600
        active:bg-green-700
        disabled:bg-green-800
        ring-green-500/60
        focus:ring-green-700/50;
    }

    .btn-red {
        @apply bg-red-700
        hover:bg-red-600
        active:bg-red-700
        disabled:bg-red-800
        ring-red-400/80
        focus:ring-red-700/50;
    }

    .btn-rose {
        @apply bg-gradient-to-r
        from-pink-600
        to-rose-600
        hover:from-pink-700
        hover:to-rose-700
        active:from-pink-800
        active:to-rose-800
        disabled:bg-rose-600
        ring-rose-300/80
        focus:ring-rose-500/50
        ring-2;
    }

    .btn-blue {
        @apply bg-blue-700
        hover:bg-blue-600
        active:bg-blue-700
        disabled:bg-blue-800
        ring-blue-500/60
        focus:ring-blue-700/50;
    }

    .btn-indigo {
        @apply bg-indigo-700
        hover:bg-indigo-600
        active:bg-indigo-700
        disabled:bg-indigo-800
        ring-indigo-500/60
        focus:ring-indigo-700/50
        ring-2;
    }

    .btn-indigo-left {
        @apply bg-gradient-to-r
        from-indigo-600
        to-blue-600
        hover:from-indigo-700
        hover:to-blue-700
        active:from-indigo-800
        active:to-blue-800
        disabled:bg-blue-600
        ring-blue-400/80
        focus:ring-blue-500/50
        ring-2;
    }

    .btn-indigo-right {
        @apply bg-gradient-to-r
        from-blue-600
        to-indigo-600
        hover:from-blue-700
        hover:to-indigo-700
        active:from-blue-800
        active:to-indigo-800
        disabled:bg-indigo-600
        ring-indigo-400/80
        focus:ring-indigo-500/50
        ring-2;
    }

    .btn-violet {
        @apply bg-violet-400
        hover:bg-violet-300
        focus:bg-violet-600
        active:bg-violet-500
        disabled:bg-violet-600
        ring-violet-400/50
        focus:ring-violet-400/50
        focus:text-violet-50;
    }

    .btn-violet.active {
        @apply bg-violet-500 text-violet-50;
    }

    .btn-outcome {
        @apply bg-gradient-to-b
        from-purple-200
        to-indigo-200
        hover:from-purple-300
        hover:to-indigo-300
        focus:ring-purple-500/50
        active:from-purple-400/50
        active:to-indigo-400/50
        disabled:from-purple-600
        disabled:to-indigo-600
        ring-purple-300/50
        ring-2;
    }

    .btn-outcome.active {
        @apply from-purple-400/50 to-indigo-400/50;
    }

    .btn-randomize {
        @apply bg-violet-400
        hover:bg-violet-500
        focus:bg-violet-600
        active:bg-violet-500
        disabled:bg-violet-700
        ring-violet-400/50
        focus:ring-violet-400/50
        ring-2
        focus:text-violet-50;
    }

    .btn-randomize.active {
        @apply bg-violet-500 text-violet-50;
    }

    /*Font styling*/
    .ff-unset {
        font-family: unset;
    }
    /* Numeric styling */
    .numeric {
        @apply font-mono;
    }

    .numeric-unit:before {
        content: " ";
    }

    .decimal {
        @apply text-[75%];
    }
    /* Animations */
    .glow {
        /* animation: glow_effect 1s ease-in-out infinite alternate; */
        /* text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073; */
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
            0 0 40px #e60073;
    }

    @keyframes glow_effect {
        from {
            text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
                0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073,
                0 0 70px #e60073;
        }

        to {
            text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
                0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6,
                0 0 80px #ff4da6;
        }
    }

    .neon {
        /* animation: neon_effect 3s infinite alternate; */
        /* text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #0fa, 0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa, 0 0 150px #0fa; */
        text-shadow: 0 0 40px #0fa, 0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa,
            0 0 150px #0fa;
    }

    @keyframes neon_effect {
        0%,
        18%,
        22%,
        25%,
        53%,
        57%,
        100% {
            text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff,
                0 0 40px #0fa, 0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa,
                0 0 150px #0fa;
        }

        20%,
        24%,
        55% {
            text-shadow: none;
        }
    }

    .blinker {
        animation: blinker_effect 1s linear infinite;
    }

    @keyframes blinker_effect {
        50% {
            opacity: 0;
        }
    }
    /* Borders */
    .border-bottom-t1 {
        border-right: 0;
        border-left: 0;
        border-top: 0;
        border-bottom: 1px solid;
        border-image: linear-gradient(
                90deg,
                rgba(69, 43, 111, 0.25) 0%,
                rgba(255, 255, 255, 0.25) 100%
            )
            1;
    }

    .border-bottom-t2 {
        border-right: 0;
        border-left: 0;
        border-top: 0;
        border-bottom: 1px solid;
        border-image: linear-gradient(
                90deg,
                rgba(140, 78, 156, 0.25) 0%,
                rgba(255, 255, 255, 0.25) 100%
            )
            1;
    }

    .border-bottom-t3 {
        border-right: 0;
        border-left: 0;
        border-top: 0;
        border-bottom: 1px solid;
        border-image: linear-gradient(
                90deg,
                rgba(55, 17, 65, 0.15) 0%,
                rgba(255, 255, 255, 0.15) 100%
            )
            1;
    }

    .border-bottom-t4 {
        border-right: 0;
        border-left: 0;
        border-top: 0;
        border-bottom: 1px solid;
        border-image: linear-gradient(
                90deg,
                rgba(85, 25, 98, 0.15) 0%,
                rgba(255, 255, 255, 0.15) 100%
            )
            1;
    }

    .bg-diagonal-lines {
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.05' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    }

    .bg-charlie-brown {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%239C92AC' fill-opacity='0.15'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
}
